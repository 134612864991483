import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const displayBrandSlice = createSlice({
  name: "displayBrand",
  initialState,
  reducers: {
    setDisplayBrandsData: (state, action) => {
      state.setDisplayBrandsData = action.payload;
    },
    setDisplayBrandsOptions: (state, action) => {
      state.setDisplayBrandsOptions = action.payload;
    },
  },
});

export const { setDisplayBrandsData, setDisplayBrandsOptions } =
  displayBrandSlice.actions;

export default displayBrandSlice.reducer;
