import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const writersSlice = createSlice({
  name: "writers",
  initialState,
  reducers: {
    setWritersData: (state, action) => {
      state.setWritersData = action.payload;
    },
    setWritersOptions: (state, action) => {
      state.setWritersOptions = action.payload;
    },
  },
});

export const { setWritersData, setWritersOptions } = writersSlice.actions;

export default writersSlice.reducer;
