import api from "service/api";

export function login(data) {
  return api.post("/auth/login", data);
}

export function forgotPassword(data) {
  return api.post(`/auth/forgot-password`, data);
}

export function verifyOTP(data) {
  return api.post(`/auth/verify-otp`, data);
}

export function resetPassword(data) {
  return api.post(`/auth/reset-password`, data);
}
