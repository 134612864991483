import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import counters from "./counters";
import config from "./config";
import writers from "./writers";
import blogs from "./blogs";
import displayBrands from "./displayBrands";
import openpositions from "./openpositions";
import contacts from "./contacts";
import portfolio from "./portfolio";
import clients from "./clients";
import applyJob from "./applyJob";
import newsLetter from "./newsLetter";
import footer from "./footer";
import service from "./service";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    counters,
    config,
    writers,
    blogs,
    displayBrands,
    openpositions,
    contacts,
    portfolio,
    clients,
    applyJob,
    newsLetter,
    footer,
    service,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
