import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setBlogCategoryData: (state, action) => {
      state.setBlogCategoryData = action.payload;
    },
    setBlogCategoryOptions: (state, action) => {
      state.setBlogCategoryOptions = action.payload;
    },
    setJobCategoryData: (state, action) => {
      state.setJobCategoryData = action.payload;
    },
    setJobCategoryOptions: (state, action) => {
      state.setJobCategoryOptions = action.payload;
    },
    setPortfolioCategoryData: (state, action) => {
      state.setPortfolioCategoryData = action.payload;
    },
    setPortfolioCategoryOptions: (state, action) => {
      state.setPortfolioCategoryOptions = action.payload;
    },
    setJobTypeData: (state, action) => {
      state.setJobTypeData = action.payload;
    },
    setJobTypeOptions: (state, action) => {
      state.setJobTypeOptions = action.payload;
    },
    setProjectCategoryData: (state, action) => {
      state.setProjectCategoryData = action.payload;
    },
    setProjectCategoryOptions: (state, action) => {
      state.setProjectCategoryOptions = action.payload;
    },
    setEmailData: (state, action) => {
      state.setEmailData = action.payload;
    },
  },
});

export const {
  setBlogCategoryData,
  setBlogCategoryOptions,
  setJobCategoryData,
  setJobCategoryOptions,
  setPortfolioCategoryData,
  setPortfolioCategoryOptions,
  setJobTypeData,
  setJobTypeOptions,
  setProjectCategoryData,
  setProjectCategoryOptions,
  setEmailData,
} = configSlice.actions;

export default configSlice.reducer;
