import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    setClientsData: (state, action) => {
      state.setClientsData = action.payload;
    },
    setClientsOptions: (state, action) => {
      state.setClientsOptions = action.payload;
    },
  },
});

export const { setClientsData, setClientsOptions } = clientSlice.actions;

export default clientSlice.reducer;
