import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const newsLetterSlice = createSlice({
  name: "newsLetter",
  initialState,
  reducers: {
    setnewsLetterData: (state, action) => {
      state.setnewsLetterData = action.payload;
    },
  },
});

export const { setnewsLetterData } = newsLetterSlice.actions;

export default newsLetterSlice.reducer;
