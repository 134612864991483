import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const countersSlice = createSlice({
  name: "counters",
  initialState,
  reducers: {
    setCounterData: (state, action) => {
      state.setCounterData = action.payload;
    },
  },
});

export const { setCounterData } = countersSlice.actions;

export default countersSlice.reducer;
