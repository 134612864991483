import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServiceData: (state, action) => {
      state.setServiceData = action.payload;
    },
  },
});

export const { setServiceData } = serviceSlice.actions;

export default serviceSlice.reducer;
