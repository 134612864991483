import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setContactsData: (state, action) => {
      state.setContactsData = action.payload;
    },
    setReadContactsData: (state, action) => {
      state.setReadContactsData = action.payload;
    },
    setUnReadContactsData: (state, action) => {
      state.setUnReadContactsData = action.payload;
    },
  },
});

export const { setContactsData, setUnReadContactsData, setReadContactsData } =
  contactsSlice.actions;

export default contactsSlice.reducer;
