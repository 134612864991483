import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {
    setPortfolioData: (state, action) => {
      state.setPortfolioData = action.payload;
    },
  },
});

export const { setPortfolioData } = portfolioSlice.actions;

export default portfolioSlice.reducer;
